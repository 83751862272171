import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SlArrowLeft } from "react-icons/sl";
import { CiTrash } from "react-icons/ci";
import packData from "../../packs.json";

function PackDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const pack = packData.find((p) => p.id === parseInt(id, 10));
  if (!pack) {
    return <div>Pack not found!</div>; // Eğer pack bulunamazsa
  }
  return (
    <div className="flex flex-col">
      <div className="w-full p-6 bg-white flex justify-between items-center flex-wrap gap-11">
        <button className="p-0" onClick={() => navigate(-1)}>
          <SlArrowLeft size={20} />
        </button>
        <button className="p-0">
          <CiTrash size={20} />
        </button>
        <div className="w-full">
          <h1 className="font-poppins">{pack.name}</h1>
        </div>
      </div>
      <div className="w-full p-3">
        {/* pack items list */}
        <div className="flex flex-col gap-3 bg-white p-3 h-[calc(100vh-86px)] overflow-y-auto scrollHide">
          {pack.items.map((item, index) => (
            <div
              key={index}
              className="flex justify-between items-center w-full"
            >
              <label className="flex gap-2 items-center">
                <input
                  type="checkbox"
                  className="cursor-pointer rounded-full bg-green-50 border-gray-300 text-green-500 focus:ring-0"
                />
                <span>{item.name}</span>
              </label>
              <div className="text-xs">{item.quantity}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PackDetail;
// rounded-full h-8 w-8 cursor-pointer bg-red-100 border-red-300 text-red-600 focus:ring-red-200
