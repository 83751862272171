import React from "react";
import Header from "./header";
import Pack from "../Pack/pack";

// toast
import toast, { Toaster } from "react-hot-toast";

// default packs
import packages from "../../packs.json";

function Home() {
  const notify = () =>
    toast("Not working yet!", {
      icon: "🚧",
    });

  return (
    <div className="flex flex-col gap-7 h-full">
      <Header />
      <div className="px-3 py-6 w-full h-full bg-[#f0f4fd] rounded-t-3xl">
        <div className="flex flex-col gap-3">
          {packages.map((pack, index) => (
            <Pack key={index} pack={pack} />
          ))}
        </div>
      </div>
      <button
        onClick={notify}
        className="fixed z-20 font-poppins text-sm left-1/2 transform -translate-x-1/2 bottom-5 p-3 bg-white w-[calc(100%-24px)] max-w-[calc(400px-24px)]"
      >
        🎒 Create New Pack
      </button>
      <Toaster />
    </div>
  );
}

export default Home;
