import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./index";
import Pack from "../Pack/packDetail";

function CustomRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/pack/:id" element={<Pack />} />
    </Routes>
  );
}

export default CustomRoutes;
