import React from "react";
import { RiSettings2Fill } from "react-icons/ri";

// toast
import toast, { Toaster } from "react-hot-toast";

function Header() {
  const notify = () =>
    toast("Not working yet!", {
      icon: "🚧",
    });
  return (
    <div>
      <div className="w-full px-5 pt-5 flex justify-between text-white">
        <div className="text-xl font-poppins font-semibold">Packs</div>
        <button onClick={notify}>
          <RiSettings2Fill />
        </button>
      </div>
      <Toaster />
    </div>
  );
}

export default Header;
