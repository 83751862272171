import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CustomRoutes from "./components/Home/routes.js";

function App() {
  return (
    <Router>
      <div className="w-full h-full bg-[url('images/bg.png')] bg-cover bg-opacity-70">
        <div className="max-w-[400px] w-full mx-auto relative h-full">
          <Routes>
            <Route path="/*" element={<CustomRoutes />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
