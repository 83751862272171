import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";

function Pack({ pack }) {
  const formattedDate = useMemo(() => {
    const date = new Date(pack.date);
    return date.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }, [pack.date]);
  return (
    <NavLink to={`/pack/${pack.id}`}>
      <div className="p-3 bg-white rounded-xl shadow-lg shadow-gray-100">
        <div className="font-poppins font-semibold">{pack.name}</div>
        <div className="text-xs font-poppins">{formattedDate}</div>
        <div className="text-xs font-poppins">{pack.items.length} items</div>
      </div>
    </NavLink>
  );
}

export default Pack;
